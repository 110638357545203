#modal {
	width: 600px;
	height: 400px;
	background: black;
	border-radius: 8px;
	display: grid;
	grid-template-rows: 48px auto;
}

#modal-heading {
	display: grid;
	grid-template-columns: auto 48px;
	align-items: center;
}

#modal-title {
	display: flex;
	justify-content: center;
	padding-left: 48px;
	font-weight: bold;
}

#modal-close {
	border-radius: 14px;
	border: solid 1px white;
	width: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 3px;
	cursor: pointer;
	&:hover {
		background: rgba(255, 255, 255, 0.2);
	}
}
