body {
	background: #111;
	color: white;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

#nav-bar {
	background: none;
	color: white;
	max-height: 48px;
	border-bottom-color: rgba(255, 255, 255, 0.25);

	.container {
		width: 100%;

		.withSidePadding {
			grid-template-columns: 100%;
		}
	}

	#hamburger {
		path {
			stroke: white;
		}
	}

	#logo-and-account-container {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	#account-placeholder {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		border-radius: 12px;
		background-image: linear-gradient(180deg, #fbd249 0%, #f5a623 100%);
		font-weight: bold;
		font-size: 10px;
		color: #111111;
		text-align: center;

		@media screen and (max-width: 768px) {
			display: none;
		}
	}
}

#homepage {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 16px;
	width: calc(100vw - 32px);
	height: calc(100vh - 48px);

	#dashboards-list {
		margin-top: 64px;
		width: 100%;
		min-height: 100%;
		height: initial;
		padding: 24px;
		background: rgba(255, 255, 255, 0.05);
		border: solid 1px rgba(255, 255, 255, 0.15);
		border-radius: 8px;

		h1 {
			margin-top: 0px;
			padding-top: 0px;
		}

		ul#dashboards {
			list-style-type: none;
			padding-inline-start: 0px;
			margin-bottom: 36px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			column-gap: 24px;
			row-gap: 24px;
			li {
				padding: 16px;
				width: calc(100% - 16px);
				background: rgba(255, 255, 255, 0.05);
				border-radius: 8px;
				border: solid 1px rgba(255, 255, 255, 0.15);
				font-weight: 700;

				.dashboard-name-and-actions {
					display: flex;
					align-items: center;
					justify-content: space-between;

					a {
						color: white;
						text-decoration: none;
					}
				}

				.dashboard-preview {
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}

	#dashboards-list-heading {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
}

#new-dashboard-page,
#edit-dashboard-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: calc(100vh - 56px);
}

#new-dashboard-form,
#edit-dashboard-form {
	padding: 48px;
	background: rgba(255, 255, 255, 0.05);
	border-radius: 8px;

	h1 {
		margin-top: 0px;
	}

	.form-field {
		margin-bottom: 24px;
	}
}
