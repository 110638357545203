.notification {
	box-sizing: border-box;
	padding: 0px 24px;
	position: fixed;
	bottom: 24px;
	right: 24px;
	width: 240px;
	height: 40px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: #a4e04a;
	color: rgba(0, 0, 0, 0.7);
	font-weight: 500;
	border-radius: 4px;
}
