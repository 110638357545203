.widget-button {
	display: flex;
	align-items: center;
	cursor: pointer;

	span {
		margin-left: 8px;
		color: rgba(255, 255, 255, 0.7);
	}
}
